<script setup lang="ts">
import { useNuxtApp } from 'nuxt/app';

import NounsNav from './NounsNav.vue';

import useConfig from '~/composables/useConfig.ts';
import { Noun, type Source, SourceLibrary } from '~/src/classes.ts';

const { $translator: translator } = useNuxtApp();
useSimpleHead({
    title: translator.translate('nouns.personNouns.header'),
    description: translator.translate('nouns.personNouns.info')[0],
}, translator);

const config = useConfig();

const personNouns = [
    new Noun(config, {
        id: 'kandydat',
        masc: 'kandydat',
        fem: 'kandydatka',
        neutr: 'osoba kandydująca',
        mascPl: 'kandydaci',
        femPl: 'kandydatki',
        neutrPl: 'osoby kandydujące',
    }),
    new Noun(config, {
        id: 'kucharz',
        masc: 'kucharz',
        fem: 'kucharka',
        neutr: 'osoba gotująca|osoba kucharska',
        mascPl: 'kucharze',
        femPl: 'kucharki',
        neutrPl: 'osoby gotujące|osoby kucharskie',
    }),
    new Noun(config, {
        id: 'mężczyzna',
        masc: 'mężczyzna',
        fem: 'kobieta',
        neutr: 'osoba|osoba niebinarna',
        mascPl: 'mężczyźni',
        femPl: 'kobiety',
        neutrPl: 'osoby|osoby niebinarne',
    }),
    new Noun(config, {
        id: 'partner',
        masc: 'partner',
        fem: 'partnerka',
        neutr: 'osoba partnerska',
        mascPl: 'partnerzy',
        femPl: 'partnerki',
        neutrPl: 'osoby partnerskie',
    }),
    new Noun(config, {
        id: 'prezes',
        masc: 'prezes',
        fem: 'prezeska',
        neutr: 'osoba prezesująca',
        mascPl: 'prezesi',
        femPl: 'prezeski',
        neutrPl: 'osoby prezesujące',
    }),
];

const sources = ref<Record<string, Source[] | undefined>>();
onMounted(async () => {
    const rawSources = await $fetch('/api/sources?pronoun=osobatywy');
    sources.value = {
        '': new SourceLibrary(config, rawSources).getForPronoun('osobatywy'),
    };
});
</script>

<template>
    <Page>
        <NounsNav />

        <h2>
            <Icon v="user-friends" />
            <T>nouns.personNouns.header</T>
            <small><NormativeBadge /></small>
        </h2>

        <div>
            <T>nouns.personNouns.info</T>
            <CensusStat type="nouns" item="osobatywy" colour="info" />
            <Share :title="$t('nouns.personNouns.header')" />
        </div>

        <section>
            <BlogEntriesList :posts="['krótka-notka-o-osobatywach', 'osobatywy-nie-takie-straszne', 'osoby-polskie']" />
        </section>

        <details open class="border mb-3">
            <summary class="bg-light p-3">
                <h4 class="h5 d-inline">
                    <T>nouns.examples</T>
                </h4>
            </summary>
            <div class="border-top table-responsive">
                <table class="table table-striped table-hover table-fixed-3">
                    <thead>
                        <tr>
                            <th class="text-nowrap">
                                <Icon v="mars" />
                                <T>nouns.masculine</T>
                            </th>
                            <th class="text-nowrap">
                                <Icon v="venus" />
                                <T>nouns.feminine</T>
                            </th>
                            <th class="text-nowrap">
                                <Icon v="neuter" />
                                <T>nouns.personNouns.label</T>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="noun in personNouns" :key="noun.id">
                            <td>
                                <ul class="list-singular">
                                    <li v-for="w in noun.masc">
                                        {{ w }}
                                    </li>
                                </ul>
                                <ul class="list-plural">
                                    <li v-for="w in noun.mascPl">
                                        {{ w }}
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul class="list-singular">
                                    <li v-for="w in noun.fem">
                                        {{ w }}
                                    </li>
                                </ul>
                                <ul class="list-plural">
                                    <li v-for="w in noun.femPl">
                                        {{ w }}
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul class="list-singular">
                                    <li v-for="w in noun.neutr">
                                        {{ w }}
                                    </li>
                                </ul>
                                <ul class="list-plural">
                                    <li v-for="w in noun.neutrPl">
                                        {{ w }}
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </details>

        <section v-if="sources && Object.keys(sources).length">
            <Literature :sources="sources" />
        </section>
    </Page>
</template>
